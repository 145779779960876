import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { UUID } from "crypto";
import validator from "validator";
import Grid from "@mui/material/Unstable_Grid2";
import SurveyComponent from "../components/Survey";
import { HttpService } from "../services/HttpService";
import { useNavigate } from "react-router-dom";
import { Survey } from "../model/Survey";
import { Answer } from "../model/Answer";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../theme";
import { FailModal } from "../components/FailModal";
import { isSurvey } from "../util/validate";
import { AxiosError } from "axios";
import Prompt from "../components/Prompt";

export const Home = () => {
  const navigate = useNavigate();
  const httpService = new HttpService();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isPreview, setIsPreview] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [success, setSuccess] = useState(false);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [open, setOpen] = useState(false);
  const [buttonReady, setButtonReady] = useState(true);

  const load = () => {
    if (!validator.isUUID(location.pathname.substring(1, 37))) {
      navigate("/404");
    } else {
      const id = location.pathname.substring(1, 37) as UUID;
      httpService.get(id, searchParams).then((result) => {
        decideAction(result);
      });
    }
  };

  const decideAction = (result: Survey | AxiosError) => {
    if (!isSurvey(result)) {
      chooseError(result);
    } else {
      doPreviewActionsIfPreview();
      sortQuestionsAndOptions(result);
      setSurvey(result);
    }
  };

  const chooseError = (error: AxiosError) => {
    if (error.response?.status === 403) {
      setInactive(true);
    } else {
      navigate("/404");
    }
  };

  const loadAnswers = () => {
    httpService.getAnswers(searchParams).then((answers) => {
      if (!!answers) setAnswers(answers);
    });
  };

  const doPreviewActionsIfPreview = () => {
    loadAnswers();
    if (!!searchParams.get("token")) {
      setIsPreview(true);
      window.history.replaceState(null, "Heisi IT tagasiside", "/preview");
    }
  };

  const sortQuestionsAndOptions = (survey: Survey) => {
    survey.questions.sort((a, b) => a.questionNum - b.questionNum);
    survey.questions.forEach((question) =>
      question.options.sort((a, b) => a.answerNum - b.answerNum)
    );
  };

  const handleOnSubmit = (answers: Answer[]) => {
    setButtonReady(false);

    httpService.save(survey!.id, answers).then((res) => {
      if (!!res) {
        setSuccess(true);
      } else {
        setOpen(true);
        setButtonReady(true);
      }
    });
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div className="base">
      <ThemeProvider theme={theme}>
        <Grid container spacing={0} disableEqualOverflow>
          <Grid xs>
            <div></div>
          </Grid>

          {open && <FailModal open={open} setOpen={setOpen} />}

          {survey && !success && !inactive && (
            <SurveyComponent
              survey={survey}
              answers={answers}
              isPreview={isPreview}
              handleOnSubmit={handleOnSubmit}
              buttonReady={buttonReady}
            />
          )}

          {success && <Prompt success={true} />}

          {inactive && <Prompt success={false} />}

          <Grid xs>
            <div></div>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default Home;
