import Card from "@mui/material/Card";
import Grid from "@mui/material/Unstable_Grid2";

import QuestionType from "../enum/QuestionType";

import { useForm } from "react-hook-form";
import { Question } from "../model/Question";
import { TextQuestion } from "../components/TextQuestion";
import { CheckboxQuestion } from "../components/CheckboxQuestion";
import { Logo } from "../components/Logo";
import { SurveyProps } from "../model/props/SurveyProps";
import { RadioQuestion } from "./RadioQuestion";
import {
  buildAnswers,
  getInitialValues,
  getInitialValuesFromAnswers,
} from "../util/init";
import { AppButton } from "./Button";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export const SurveyComponent = ({
  survey,
  answers,
  isPreview,
  handleOnSubmit,
  buttonReady,
}: SurveyProps) => {
  const { t } = useTranslation();
  const initialValues =
    !answers || answers.length === 0
      ? getInitialValues(survey)
      : getInitialValuesFromAnswers(survey, answers);

  const { handleSubmit, control, setValue, getValues, reset } = useForm({
    defaultValues: { ...initialValues },
  });

  const onSubmit = (data: any) => {
    if (isPreview) return;
    const answers = buildAnswers(survey, data);
    handleOnSubmit(answers);
  };

  useEffect(() => {
    if (isPreview) {
      reset({ ...initialValues });
    }
  }, [answers]);

  return (
    <Grid xs={12} sm={9} md={7} lg={5} xl={4}>
      <Logo xs={150} sm={200} md={225} lg={250} xl={250} padding={2} />

      <Card
        sx={{
          marginBottom: 5,
          borderRadius: { xs: 0, sm: 5, md: 5, lg: 5, xl: 5 },
        }}
      >
        <div className="border">
          <h1 className="title">{survey.title}</h1>

          <div>
            {survey.questions &&
              survey.questions.map(function (question: Question) {
                return (
                  <div key={question.id}>
                    <h2 className="question-text">
                      {question.answerRequired ? `${question.text}\u00A0*` : question.text}
                    </h2>

                    {(question.questionType === QuestionType.ShortText ||
                      question.questionType === QuestionType.LongText) && (
                      <TextQuestion
                        key={question.id}
                        question={question}
                        control={control}
                        name={question.id}
                      />
                    )}

                    {question.questionType === QuestionType.Checkbox && (
                      <CheckboxQuestion
                        key={question.id}
                        question={question}
                        control={control}
                        setValue={setValue}
                        name={question.id}
                        getValues={getValues}
                      />
                    )}

                    {question.questionType === QuestionType.Radio && (
                      <RadioQuestion
                        key={question.id}
                        question={question}
                        control={control}
                        name={question.id}
                      />
                    )}
                  </div>
                );
              })}
          </div>

          <AppButton
            handleClick={handleSubmit(onSubmit)}
            buttonText={t("button.submit")}
            buttonReady={buttonReady}
          />
        </div>
      </Card>
    </Grid>
  );
};

export default SurveyComponent;
