import { Box, Button, CircularProgress } from "@mui/material";
import { defaultColor, defaultFontFamily } from "../theme";

export interface ButtonProps {
  handleClick: Function;
  buttonText: string;
  buttonReady?: boolean;
}

export const AppButton = ({
  handleClick,
  buttonText,
  buttonReady,
}: ButtonProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {buttonReady && (
        <Button
          className="button"
          sx={{
            background: defaultColor,
            fontFamily: [defaultFontFamily],
            fontWeight: 400,
            borderRadius: "10px",
          }}
          variant="contained"
          onClick={() => handleClick()}
        >
          {buttonText}
        </Button>
      )}
      {!buttonReady && <CircularProgress />}
    </Box>
  );
};
