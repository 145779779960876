import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { CheckboxFormInputProps } from "../model/props/FormInputProps";
import { AnswerOption } from "../model/AnswerOption";
import { useTranslation } from "react-i18next";

export const CheckboxQuestion: React.FC<CheckboxFormInputProps> = ({
  name,
  question,
  control,
  setValue,
  getValues,
}) => {
  const { t } = useTranslation();

  const handleSelect = (value: string) => {
    const isPresent = getValues()[name].indexOf(value);
    if (isPresent !== -1) {
      const remaining = (getValues()[name] as string[]).filter(
        (item: string) => item !== value
      );
      setValue(name, remaining);
    } else {
      const withAdded = [...(getValues()[name] as string[]), value];
      setValue(name, withAdded);
    }
  };

  return (
    <Controller
      name={question.id}
      control={control}
      rules={{
        required:
          question.answerRequired && t("validation.question.isMandatory"),
      }}
      render={({ fieldState: { error } }) => (
        <Box sx={{ display: "flex" }}>
          <FormControl
            error={!!error}
            component="fieldset"
            sx={{
              paddingLeft: 3,
              paddingRight: 3,
              boxSizing: "border-box",
              class: "text",
            }}
            variant="standard"
          >
            <FormGroup>
              {question.options.map((option: AnswerOption) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getValues()[name].includes(option.id)}
                        onChange={() => handleSelect(option.id)}
                      />
                    }
                    label={option.valueText}
                    key={option.id}
                  />
                );
              })}
            </FormGroup>
            <FormHelperText>
              {error && getValues()[name].length === 0 ? error.message : " "}
            </FormHelperText>
          </FormControl>
        </Box>
      )}
    />
  );
};
