import { Box, Card, Grid, Link, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Logo } from "./Logo";
import { defaultColor } from "../theme";

export interface PromptProps {
  success: boolean;
}

export const Prompt = ({ success }: PromptProps) => {
  const { t } = useTranslation();

  return (
    <Grid
      xs={12}
      sm={9}
      md={7}
      lg={5}
      xl={4}
      alignItems="center"
      flexDirection={"column"}
      item={true}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "100vh" }}
      >
        <Card sx={{ borderRadius: 5, margin: "2rem" }}>
          <div className="prompt-container text">
            <Logo xs={100} sm={100} md={125} lg={125} xl={150} padding={0} />

            <Box padding={3} textAlign={"center"}>
              {success ? t("success.tnx") : t("inactive.inactive")} <br />
              {success
                ? t("success.moreToAdd")
                : t("inactive.tryAgainOrContact")}{" "}
              <br />
              <Link
                sx={{ textDecorationColor: defaultColor }}
                href={t("mail.mailto")}
              >
                {t("mail.eMailAddress")}
              </Link>
            </Box>
          </div>
        </Card>
      </Stack>
    </Grid>
  );
};

export default Prompt;
