import { UUID } from "crypto";
import { Survey } from "../model/Survey";
import httpClient from "./HttpClient";
import { isSurvey } from "../util/validate";
import { Answer } from "../model/Answer";
import { AxiosError } from "axios";

export class HttpService {
  async get(id: UUID, params?: URLSearchParams): Promise<Survey | AxiosError> {
    let result;
    try {
      if (!!params && params.get("token")) {
        result = await httpClient.get(`preview/${id}`, {
          headers: {
            Authorization: `Bearer ${params.get("token")}`,
          },
        });
      } else {
        result = await httpClient.get(id);
      }

      if (isSurvey(result.data)) {
        return result.data as unknown as Survey;
      }
    } catch (error: any) {
      return error as AxiosError;
    }
    return result as any as AxiosError;
  }

  async getAnswers(params: URLSearchParams): Promise<Answer[] | undefined> {
    if (!params.get("answer")) return undefined;
    try {
      const result = await httpClient.get(
        `preview/answer/${params.get("answer")}`,
        {
          headers: {
            Authorization: `Bearer ${params!.get("token")}`,
          },
        }
      );

      return result.data as Answer[];
    } catch (error) {
      return undefined;
    }
  }

  async save(id: UUID, answers: Answer[]): Promise<Answer[] | undefined> {
    try {
      let result = await httpClient.post(id, answers);

      if (result.status === 201) {
        return result.data as Answer[];
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  }
}
