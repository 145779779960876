import { Survey } from "../model/Survey";
import validator from "validator";

const isSurvey = (item: any): item is Survey =>
  typeof item === "object" &&
  item !== null &&
  item.hasOwnProperty("title") &&
  typeof item.title === "string" &&
  item.hasOwnProperty("id") &&
  validator.isUUID(item.id);

const hasIllegalCharacter = (result: string) => {
  const regex = new RegExp("^[€ !%'-Za-zŠšŽžÜüÕõÖöÄä\"\n_]*$");
  return !regex.test(result);
};

export { isSurvey, hasIllegalCharacter };
