import { UUID } from "crypto";
import QuestionType from "../enum/QuestionType";
import { Answer } from "../model/Answer";
import { Survey } from "../model/Survey";

export const getInitialValues = (survey: Survey) => {
  type res = { [key: string]: string | string[] };
  const initialValues: res = {};

  survey.questions.forEach((question) => {
    initialValues[question.id as string] =
      question.questionType === QuestionType.Checkbox ? ([] as string[]) : "";
  });

  return initialValues;
};

export const getInitialValuesFromAnswers = (
  survey: Survey,
  answers: Answer[]
) => {
  type res = { [key: string]: string | string[] };
  const initialValues: res = {};

  if (!answers) return;

  survey.questions.forEach((question) => {
    initialValues[question.id as string] =
      question.questionType === QuestionType.Checkbox
        ? answers
            .filter((a) => a.questionId === question.id)
            .map((ans) => ans.optionId ?? ans.valueText ?? "")
        : answers
            .filter((a) => a.questionId === question.id)
            .map((ans) => ans.optionId ?? ans.valueText ?? "")
            .at(0)!;
  });

  return initialValues;
};

export const buildAnswers = (survey: Survey, data: any) => {
  const answers = [] as Answer[];
  const keys = Object.keys(data);

  keys.forEach((key) => {
    const result = data[key];
    const question = survey.questions.filter(
      (question) => question.id === key
    )[0];

    if (question.questionType === QuestionType.Checkbox) {
      result.forEach((answer: UUID) =>
        answers.push({ questionId: key as UUID, optionId: answer })
      );
    } else if (
      (question.questionType === QuestionType.ShortText ||
        question.questionType === QuestionType.LongText) &&
      result.trim().length !== 0
    ) {
      answers.push({ questionId: key as UUID, valueText: result });
    } else if (result.trim().length !== 0) {
      answers.push({ questionId: key as UUID, optionId: result });
    }
  });

  return answers;
};
