import { Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import NotFound from "./views/NotFound";

function Root() {
  return (
    <Routes>
      <Route path="/404" element={<NotFound />} />
      <Route path="/*" element={<Home />} />
    </Routes>
  );
}

export default Root;
