import axios from "axios";

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  timeout: Number(process.env.REACT_APP_AXIOS_TIMEOUT),
  headers: {
    "Content-type": "application/json",
  },
});

export default httpClient;
