import { FormControl, FormHelperText, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../model/props/FormInputProps";
import { hasIllegalCharacter } from "../util/validate";
import QuestionType from "../enum/QuestionType";
import { useTranslation } from "react-i18next";

export const TextQuestion = ({ question, control }: FormInputProps) => {
  const { t } = useTranslation();
  const maxLength =
    question.questionType === QuestionType.ShortText ? 100 : 500;

  return (
    <Controller
      name={question.id}
      control={control}
      rules={{
        validate: (value) => {
          if (question.answerRequired && value.trim().length === 0)
            return t("validation.question.isMandatory");
          if (hasIllegalCharacter(value))
            return t("validation.answer.containsIllegalCharacter");
          return true;
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormControl
            sx={{ paddingLeft: 3, paddingRight: 3, boxSizing: "border-box" }}
            error={!!error}
            variant="standard"
            fullWidth
          >
            {question.questionType === QuestionType.ShortText && (
              <TextField
                id={question.id}
                name={question.id}
                value={value}
                inputProps={{ maxLength: maxLength }}
                onChange={onChange}
                fullWidth
                error={!!error}
                size="small"
              />
            )}

            {question.questionType === QuestionType.LongText && (
              <TextField
                id={question.id}
                name={question.id}
                minRows={3}
                value={value}
                inputProps={{ maxLength: maxLength }}
                onChange={onChange}
                fullWidth
                multiline
                error={!!error}
                size="small"
              />
            )}

            <FormHelperText
              sx={{
                display: "flex",
                justifyContent: error ? "space-between" : "end",
              }}
            >
              <span>{error?.message}</span>
              <span>
                {value ? value.length : 0}/{maxLength}
              </span>
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
