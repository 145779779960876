import Box from "@mui/material/Box";

export interface LogoProps {
  padding: number;
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export const Logo = (props: LogoProps) => {
  return (
    <Box display="flex" justifyContent="center">
      <Box
        component="img"
        sx={{
          paddingTop: {
            xs: props.padding / 2,
            sm: props.padding,
            md: props.padding,
            lg: props.padding,
            xl: props.padding,
          },
          paddingBottom: {
            xs: props.padding / 2,
            sm: props.padding,
            md: props.padding,
            lg: props.padding,
            xl: props.padding,
          },
          width: {
            xs: props.xs,
            sm: props.sm,
            md: props.md,
            lg: props.lg,
            xl: props.xl,
          },
        }}
        alt="Heisi logo"
        src="heisi.png"
      />
    </Box>
  );
};
