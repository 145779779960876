import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../model/props/FormInputProps";
import { useTranslation } from "react-i18next";

export const RadioQuestion: React.FC<FormInputProps> = ({
  question,
  control,
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={question.id}
      control={control}
      rules={{
        required: question.answerRequired
          ? t("validation.question.isMandatory")
          : false,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl
          error={error ? true : false}
          sx={{ paddingLeft: 3, paddingRight: 3, boxSizing: "border-box" }}
          variant="standard"
        >
          <RadioGroup value={value} onChange={onChange}>
            {question.options.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.id}
                label={option.valueText}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
          <FormHelperText>{error ? error?.message : " "}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
