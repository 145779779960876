import { checkboxClasses, createTheme, radioClasses } from "@mui/material";

export const defaultFontFamily = "Open Sans";
export const defaultColor = "#2D2B72";
export const defaultTextColor = "#414042";

export const breakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          color: defaultTextColor,
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "default",
          [`&.${checkboxClasses.checked}`]: {
            color: defaultTextColor,
          },
          padding: "7px",
          [breakpointsTheme.breakpoints.between("xs", "sm")]: {
            padding: "5px",
          },
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          color: defaultColor,
        },
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          color: "default",
          [`&.${radioClasses.checked}`]: {
            color: defaultTextColor,
          },
          padding: "7px",
          [breakpointsTheme.breakpoints.between("xs", "sm")]: {
            padding: "5px",
          },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: defaultFontFamily,
          fontSize: "0.9rem",
          lineHeight: "1",
          fontWeight: 400,
          color: defaultTextColor,
          [breakpointsTheme.breakpoints.between("xs", "sm")]: {
            fontSize: "0.8rem",
          },
        },
      },
    },
  },
});
