import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { AppButton } from "./Button";
import { useTranslation } from "react-i18next";

export interface ModalProps {
  open: boolean;
  setOpen: Function;
}

export const FailModal = (props: ModalProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "50%",
            maxWidth: "540px",
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 3,
            padding: "2rem",
          }}
        >
          <div className="fail-text">
            <span>{t("fail.answeringFailed")}</span>
            <br />
            <span>{t("fail.tryAgain")}</span>
          </div>

          <AppButton
            handleClick={handleClose}
            buttonText={t("button.ok")}
            buttonReady={true}
          ></AppButton>
        </Box>
      </Modal>
    </>
  );
};
